import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { MDXProvider } from "@mdx-js/react"
import components from '../components/MDXComponents'

export default function RemarkNews({ data }) {
  const post = data.mdx
  const category = post.fields.category

  const shortcodes = { ...components }

  return (
    <Layout slug="/news/" category={category} {...data.meta.nodes[0]}>
      <div className="c-page-title">
        <h1>お知らせ</h1>
      </div>

      <div className="p-news-detail">
        <MDXProvider components={shortcodes}>
          <MDXRenderer>{post.body}</MDXRenderer>
        </MDXProvider>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    meta: allMeta(filter: {slug: {eq: "/news/"}}) {
      nodes {
        title
        description
        keywords
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      body
      fields {
        slug
        category
      }
    }
  }
`